@import "./_mantine.scss";
.container {
  padding: var(--mantine-spacing-sm);
}

.card {
  margin: 120px auto 0;
  color: var(--mantine-color-blue-8);
}

.password {
  background-color: whitesmoke;
  border: none;
  border-radius: var(--mantine-radius-xs);
}

.label {
  font-size: var(--mantine-font-size-md);
  color: var(--mantine-color-blue-8);
  padding-bottom: var(--mantine-spacing-xs);
}
